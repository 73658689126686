import config from '../config';
import { React, useContext, useRef, useState } from 'react';
import { 
    Box,
    Button,
    Container,
    FormControlLabel,
    Input,
    MenuItem,
    Grid,
    Radio,
    RadioGroup,
    Select,
    TextField
} from '@mui/material';

import {
    Cast as CastIcon, TurnedIn,
} from '@mui/icons-material';

import { LoadingButton } from '../components/exports';
import { Auth } from 'aws-amplify';
import AppInfoParser from 'app-info-parser'

import { ApiClient } from '../models/exports'

import { EnvContext, PermissionContext } from '../providers/exports';
import { ProductNames, ModelNames } from '../constants/exports'

export function AddPage(props) {
    const [packageInfo, setPackageInfo] = useState({
        model_name: '',
        product_name: ProductNames.Android.value,
        version_code: '',
        version_name: '',
        visibility: false,
    });
    const [tags, setTags] = useState('');

    const [loading, setLoading] = useState(false);

    const [file, setFile] = useState(null);

    const fileInput = useRef(null);

    const { env } = useContext(EnvContext);
    const { permission } = useContext(PermissionContext);

    const api = new ApiClient({});

    const checkPermission = key => {
        const productName = ProductNames[key].value;
        return (() => {
            for (let p of permission) {
                if (p.type === 'product_name' && p.value === productName) {
                    return true;
                }
                else if (p.type === 'model_name') {
                    const model = Object.values(ModelNames).find(m => m.value === p.value)
                    if (model !== undefined && model.product === productName) {
                        return TurnedIn;
                    }
                }
            }

            return false;
        })();
    }

    const checkDuplicate = async () => {
        const path = `${config[env].tapi.endpoint}/firmwares`;
        const params = {
            'and': `(model_name.eq.${packageInfo.model_name},version_code.eq.${packageInfo.version_code})`,
        }

        const [result, err] = await api.get(path, params);
        if (err !== null) {
            alert('パッケージ重複チェックでエラーが発生しました')
            return false;
        };

        if (result !== null && result.length > 0) {
            const b = window.confirm('すでに登録されています。編集画面を開きますか？')
            if (b) {
                props.history.push(`/edit/${result[0].id}`)
            }

            return false;
        }

        return true;
    }

    const register = async (idToken) => {
        const path = `${config[env].tapi.endpoint}/firmwares`;

        const params = {
            model_name: packageInfo.model_name,
            product_name: packageInfo.product_name,
            version_code: parseInt(packageInfo.version_code),
            version_name: packageInfo.version_name,
        }


        return await api.post(path, params, { Authorization: idToken });
    }

    const putBinURL = async (idToken, id) => {
        const path = `${config[env].tapi.endpoint}/firmwares/${id}/bin`;

        return await api.put(path, {}, { Authorization: idToken });
    }

    const uploadBin = async (url) => {
        // const path = `/api/upload.php`;
        // const params = new FormData();

        // params.append('file', file);
        // params.append('url', url);

        // const header = {
        //     'Content-Type': 'multipart/form-data',
        // };

        // return await api.post(path, params, header);
        const params = new FormData();
        params.append('file', file);

        const header = {
            'Content-Type': 'multipart/form-data',
        };

        return await api.put(url, params, null);
    }

    const changeAttribute = async (idToken, id) => {
        const path = `${config[env].tapi.endpoint}/firmwares/${id}`;

        let tagArray = [];
        if (tags) {
            tagArray = tags.split(',').map(t => t.trim());
        }

        const params = {
            visibility: packageInfo.visibility,
            tags: JSON.stringify(tagArray),
        };

        return await api.patch(path, params, { Authorization: idToken });
    }

    const onChangeProductName = e => {
        setPackageInfo({
            ...packageInfo,
            product_name: e.target.value,
            model_name: '',
            version_code: '',
            version_name: '',
            visibility: false,
        });

        console.log(e.target)
        if(fileInput !== null && fileInput.current !== null) {
            // マテリアルUIのコンポーネントでinputがラップされているのでこれで取得
            const dom = fileInput.current.getElementsByTagName('input')[0];
            dom.value = '';
        }

        setFile(null);
    }

    const validateFile = async e => {
        const file = e.target.files[0];
        if (file === undefined) {
            setFile(null);
            return;
        }

        //　プロダクトがAndroidの場合はパッケージの解析を行う
        if (packageInfo.product_name === ProductNames.Android.value) {
            const reason = await parseApk(file);
            if (reason !== null) {
                alert(reason);
                e.target.value = '';
                setFile(null);
                return;
            }
        }

        setFile(file);
    }

    const parseApk = async file => {
        try {
            const parser = new AppInfoParser(file);
            const result = await parser.parse();
            const { package: modelName, versionCode, versionName } = result;

            if (permission.find(p => p.type === 'model_name' && p.value === modelName) !== undefined) {
                setPackageInfo({
                    ...packageInfo,
                    model_name: result.package,
                    version_code: result.versionCode,
                    version_name: result.versionName,
                });

                return null;
            } else {
                return '許可されていないAPKです'
            }
        } catch (e) {
            return 'パッケージ解析エラー';
        }
    };

    const onUploadClick = async e => {
        if (!validate()) return;

        setLoading(true);
        await (async () => {
            const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();

            if (!(await checkDuplicate())) return;

            const [r1, e1] = await register(idToken);
            if (e1 !== null) {
                alert('パッケージ登録エラー');
                return;
            }
            console.log(r1);

            const [r2, e2] = await putBinURL(idToken, r1.id);
            if (e2 !== null) {
                const b = window.confirm('ファイルのアップロードに失敗しました。編集画面を開きますか？');
                if (b) {
                    props.history.push(`/edit/${r1.id}`);
                }
                return;
            }
            console.log(r2);

            // const [r3, e3] = await uploadBin(r2.upload_url);
            // console.log(r3);
            // if (e3 !== null) {
            //     const b = window.confirm('ファイルのアップロードに失敗しました。編集画面を開きますか？');
            //     if (b) {
            //         props.history.push(`/edit/${r1.id}`);
            //     }
            //     return;
            // }

            const uploadResult = await api.putFile(r2.upload_url, file);
            if (!uploadResult) {
                alert('S3アップロードエラー');
                return;
            }

            const [r4, e4] = await changeAttribute(idToken, r1.id);
            if (e4 !== null) {
                const b = window.confirm('パッケージ情報の書き換えに失敗しました。編集画面を開きますか？');
                if (b) {
                    props.history.push(`/edit/${r1.id}`);
                }
                return;
            }
            console.log(r4);
            alert('登録成功')
            props.history.push('/');
        })();
        setLoading(false);

    };

    const Visibility = ({...props}) => {
        return (
            <button
                style={{
                    width: '160px',
                    height: '56px',
                    fontSize: '1.5rem',
                    color: 'white',
                    borderRadius: '12px',
                    backgroundColor: (packageInfo.visibility ? '#d32f2f' : '#616161'),
                }}
                onClick={toggleVisibility}>
                {packageInfo.visibility ? (<div>公開&nbsp;&nbsp;<CastIcon /></div>) : (<div>非公開</div>)}
            </button>
        )
    };

    const toggleVisibility = () => {
        setPackageInfo({ ...packageInfo, visibility: !packageInfo.visibility })
    }

    const validate = () => {
        if (!packageInfo.product_name) {
            alert('プロダクトを選択してください');
            return false;
        }
        if (!file) {
            alert('ファイルを選択してください');
            return false;
        }
        if (!packageInfo.model_name) {
            alert('モデルを選択してください');
            return false;
        }
        if (!packageInfo.version_code) {
            alert('バージョンコードを入力してください');
            return false;
        }
        if (!packageInfo.version_name) {
            alert('バージョンネームを入力してください');
            return false;
        }

        return true;
    }

    return (
        <div>
            <Box m={4} />

            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Select
                        labelId="product-name-select-label"
                        id="product-name-select"
                        value={packageInfo.product_name}
                        fullWidth
                        onChange={onChangeProductName} >
                        {Object.keys(ProductNames).filter(key => checkPermission(key)).map((key, i) =>
                            <MenuItem key={i} value={ProductNames[key].value}>{ProductNames[key].name}</MenuItem>
                        )}
                    </Select>
                </Grid>
                <Grid item xs={3}>
                    {packageInfo.product_name !== ProductNames.Android.value
                        ? (
                            <Select
                                labelId="model-name-select-label"
                                id="model-name-select"
                                value={packageInfo.model_name}
                                fullWidth
                                onChange={e => setPackageInfo({ ...packageInfo, model_name: e.target.value })} >
                                {Object.keys(ModelNames).filter(key => ModelNames[key].product === packageInfo.product_name).map((key, i) =>
                                    <MenuItem key={i} value={ModelNames[key].value}>{ModelNames[key].name}</MenuItem>
                                )}
                            </Select>
                        ) : ''
                    }
                </Grid>
                <Grid item xs={4} />
                <Grid item xs={3}>
                    <Visibility />
                </Grid>
            </Grid>

            <TextField
                type="text"
                label="モデル"
                fullWidth={true}
                margin="normal"
                value={packageInfo.model_name}
                disabled={true} />

            <TextField
                type="text"
                label="プロダクト"
                fullWidth={true}
                margin="normal"
                value={packageInfo.product_name}
                disabled={true} />

            <Grid container spacing={4}>
                <Grid item xs={6}>
                    <TextField
                        type="number"
                        label="バージョンコード"
                        fullWidth={true}
                        margin="normal"
                        value={packageInfo.version_code}
                        onChange={e => setPackageInfo({ ...packageInfo, version_code: e.target.value })}
                        disabled={packageInfo.product_name === ProductNames.Android.value ? true : false} />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        type="text"
                        label="バージョンネーム"
                        fullWidth={true}
                        margin="normal"
                        value={packageInfo.version_name}
                        onChange={e => setPackageInfo({ ...packageInfo, version_name: e.target.value })}
                        disabled={packageInfo.product_name === ProductNames.Android.value ? true : false} />
                </Grid>
            </Grid>

            <TextField
                type="text"
                label="タグ"
                fullWidth={true}
                margin="normal"
                value={tags}
                onChange={e => setTags(e.target.value)} />

            <Box m={2} />

            <Grid container spacing={5}>
                <Grid item xs={7}>
                    <Input
                        ref={fileInput}
                        type="file"
                        fullWidth={true}
                        onChange={validateFile} />
                </Grid>
            </Grid>

            <Box m={4} />

            <LoadingButton loading={loading} variant="contained" color="primary" fullWidth={true} onClick={onUploadClick}>登録</LoadingButton>
        </div>
    );
}
