import { ProductNames } from './ProductNames'

export const ModelNames = Object.freeze({
    // PT-750
    PT_750: Object.freeze({
        name: 'PT-750',
        value: 'jp.mcapps.android.multi_payment_terminal',
        product: ProductNames.Android.value,
    }),
    PT_750_DEBUG: Object.freeze({
        name: 'PT-750-DEBUG',
        value: 'jp.mcapps.android.multi_payment_terminal.debug',
        product: ProductNames.Android.value,
    }),
    // 横浜敬老パスポータブル端末アプリ
    YOKOHAMA_SENIOR_PASS_PORTABLE: Object.freeze({
        name: 'YOKOHAMA-SENIOR-PASS-PORTABLE',
        value: 'jp.mcapps.android.bus_invehicle_portable_terminal',
        product: ProductNames.Android.value,
    }),
    YOKOHAMA_SENIOR_PASS_PORTABLE_DEBUG: Object.freeze({
        name: 'YOKOHAMA-SENIOR-PASS-PORTABLE-DEBUG',
        value: 'jp.mcapps.android.bus_invehicle_portable_terminal.debug',
        product: ProductNames.Android.value,
    }),
    YOKOHAMA_SENIOR_PASS_ADMIN_PORTABLE: Object.freeze({
        name: 'YOKOHAMA-SENIOR-PASS-ADMIN_PORTABLE',
        value: 'jp.mcapps.android.admin_portable_terminal',
        product: ProductNames.Android.value,
    }),
    YOKOHAMA_SENIOR_PASS_ADMIN_PORTABLE_DEBUG: Object.freeze({
        name: 'YOKOHAMA-SENIOR-PASS-ADMIN_PORTABLE-DEBUG',
        value: 'jp.mcapps.android.admin_portable_terminal.debug',
        product: ProductNames.Android.value,
    }),
    TAPP: Object.freeze({
        name: 'TAPP',
        value: 'jp.mcapps.android.tapp',
        product: ProductNames.Android.value,
    }),
    TAPP_DEV: Object.freeze({
        name: 'TAPP-DEV',
        value: 'jp.mcapps.android.tapp.dev',
        product: ProductNames.Android.value,
    }),
    TAPP_STG: Object.freeze({
        name: 'TAPP-STG',
        value: 'jp.mcapps.android.tapp.stg',
        product: ProductNames.Android.value,
    }),
    RAKU2_DRIVER_APP: Object.freeze({
        name: 'RAKU2-DRIVER-APP',
        value: 'jp.mcapps.rakurakudriver',
        product: ProductNames.Android.value,
    }),
    RAKU2_DRIVER_APP_DEV: Object.freeze({
        name: 'RAKU2-DRIVER-APP-DEV',
        value: 'jp.mcapps.rakurakudriver.dev',
        product: ProductNames.Android.value,
    }),
    RAKU2_DRIVER_APP_STG: Object.freeze({
        name: 'RAKU2-DRIVER-APP-STG',
        value: 'jp.mcapps.rakurakudriver.stg',
        product: ProductNames.Android.value,
    }),

    // IM-A820
    FUTABA: Object.freeze({
        name: 'FUTABA',
        value: 'FUTABA',
        product: ProductNames.IM_A820.value,
    }),
    NISHIBE: Object.freeze({
        name: 'NISHIBE',
        value: 'NISHIBE',
        product: ProductNames.IM_A820.value,
    }),
    YAZAKI_LT24: Object.freeze({
        name: 'YAZAKI-LT24',
        value: 'YAZAKI-LT24',
        product: ProductNames.IM_A820.value,
    }),
    YAZAKI_LT26: Object.freeze({
        name: 'YAZAKI-LT26',
        value: 'YAZAKI-LT26',
        product: ProductNames.IM_A820.value,
    }),
    YAZAKI_LT27_D: Object.freeze({
        name: 'YAZAKI-LT27-D',
        value: 'YAZAKI-LT27-D',
        product: ProductNames.IM_A820.value,
    }),
    OKABE_MS70_D: Object.freeze({
        name: 'OKABE-MS70-D',
        value: 'OKABE-MS70-D',
        product: ProductNames.IM_A820.value,
    }),
    FUTABA_R9_6_D: Object.freeze({
        name: 'FUTABA-R9-6-D',
        value: 'FUTABA-R9-6-D',
        product: ProductNames.IM_A820.value,
    }),
    PT_850: Object.freeze({
        name: 'PT-850',
        value: 'PT-850',
        product: ProductNames.ABT.value,
    }),
    ABT_TABLET: Object.freeze({
        name: 'ABT-TABLET',
        value: 'jp.mcapps.android.abt_busapp',
        product: ProductNames.Android.value,
    }),
    ABT_TABLET_DEBUG: Object.freeze({
        name: 'ABT-TABLET-DEBUG',
        value: 'jp.mcapps.android.abt_busapp.dev',
        product: ProductNames.Android.value,
    }),
    // DIDI: Object.freeze({
    //     name: 'DiDi',
    //     value: 'com.sdu.didi.gsui.jp',
    //     product: ProductNames.Android.value,
    // }),
    DIDI: Object.freeze({
        name: 'DiDi',
        value: 'com.didiglobal.driver',
        product: ProductNames.Android.value,
    }),
    SRIDE: Object.freeze({
        name: 'S.RIDE',
        value: 'jp.sride.driverapp',
        product: ProductNames.Android.value,
    }),
    ONETAB_REA: Object.freeze({
        name: 'REA',
        value: 'com.rea.noruuu.driver',
        product: ProductNames.Android.value,
    }),
    BUS_TICKET: Object.freeze({
        name: 'BUS-TICKT',
        value: 'jp.mcapps.android.busapp',
        product: ProductNames.Android.value,
    }),
    BUS_TICKET_DEBUG: Object.freeze({
        name: 'BUS-TICKT_DEBUG',
        value: 'jp.mcapps.android.busapp.dev',
        product: ProductNames.Android.value,
    }),
    PT_830_BUS_PAYMENT_OITA: Object.freeze({
        name: 'bus_payment_oita',
        value: 'bus_payment_oita',
        product: ProductNames.PT_830.value,
    }),
    PT_750_HOUSE_APP: Object.freeze({
        name: 'PT-750-DEBUG',
        value: 'jp.mcapps.android.houseapplication',
        product: ProductNames.Android.value,
    }),
    PT_750_VALUEDESIGN: Object.freeze({
        name: 'PT-750-VALUEDESIGN',
        value: 'jp.mcapps.android.valuedesign_payment_terminal',
        product: ProductNames.Android.value,
    }),
    PT_750_VALUEDESIGN_DEBUG: Object.freeze({
        name: 'PT-750-VALUEDESIGN-DEBUG',
        value: 'jp.mcapps.android.valuedesign_payment_terminal.debug',
        product: ProductNames.Android.value,
    }),
    PT_750_PREPAID: Object.freeze({
        name: 'PT-750-PREPAID',
        value: 'jp.mcapps.pt_prepaid',
        product: ProductNames.Android.value,
    }),
    PT_750_PREPAID_DEBUG: Object.freeze({
        name: 'PT-750-PRIPAID-DEBUG',
        value: 'jp.mcapps.pt_prepaid.debug',
        product: ProductNames.Android.value,
    }),
    UBER_CAB: Object.freeze({
        name: 'UBER-CAB',
        value: 'com.ubercab.driver',
        product: ProductNames.Android.value,
    }),
});
