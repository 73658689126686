import { createContext, useState } from 'react';
import { Env } from '../constants/Env';

export const EnvContext = createContext();

export function EnvProvider({children}) {
	const [env, setEnv] = useState(null);
	return (
		<EnvContext.Provider value={{ env, setEnv }}>
			{children}
		</EnvContext.Provider>
	);
}