import axios from 'axios';


export class ApiClient {
    constructor ({baseURL='', customHeader = async f => null}) {
        this.baseURL = baseURL;
        this.customHeader = customHeader;
    }

    async makeHeader(header) {
        return Object.assign({
            'Content-Type': 'application/json'
        }, (await this.customHeader()), header);
    }

    get(path, body={}, header={}) {
        return new Promise(async (resolve, _) => {
            const config = {
                params: body,
                headers: await this.makeHeader(header),
            };
            try {
                const response = await axios.get(this.baseURL + path, config);
                resolve([response.data, null]);
            }
            catch (e) {
                console.error(e);
                resolve([null, e]);
            }
        });
    }

    post(path, body={}, header={}) {
        return new Promise(async (resolve, _) => {
            const config = {
                headers: await this.makeHeader(header),
            };
            try {
                const response = await axios.post(this.baseURL + path, body, config);
                resolve([response.data, null]);
            }
            catch (e) {
                console.error(e);
                resolve([null, e]);
            }
        });
    }

    put(path, body={}, header={}) {
        return new Promise(async (resolve, _) => {
            const config = {
                headers: await this.makeHeader(header),
            };
            try {
                const response = await axios.put(this.baseURL + path, body, config);
                resolve([response.data, null]);
            }
            catch (e) {
                console.error(e);
                resolve([null, e]);
            }
        });
    }

    patch(path, body={}, header={}) {
        return new Promise(async (resolve, _) => {
            const config = {
                headers: await this.makeHeader(header),
            };
            try {
                const response = await axios.patch(this.baseURL + path, body, config);
                resolve([response.data, null]);
            }
            catch (e) {
                console.error(e);
                resolve([null, e]);
            }
        });
    }

    delete(path, body={}, header={}) {
        return new Promise(async (resolve, _) => {
            const config = {
                headers: await this.makeHeader(header),
                params: body,
            };
            try {
                const response = await axios.delete(this.baseURL + path, config);
                resolve([response.data, null]);
            }
            catch (e) {
                console.error(e);
                resolve([null, e]);
            }
        });
    }

    putFile(url, file) {
        return new Promise(resolve => {
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', url);
            xhr.onreadystatechange = function () {
                /*
                  readyState
                    0 UNSENT            クライアントは作成済み。open() はまだ呼ばれていない。
                    1 OPENED            open() が呼び出し済み。
                    2 HEADERS_RECEIVED  send() が呼び出し済みで、ヘッダーとステータスが利用可能。
                    3 LOADING           ダウンロード中。responseText には部分データが入っている。
                    4 DONE              操作が完了した
                */
        
                if (xhr.readyState == xhr.DONE) {
                  resolve(xhr.status == 200);
                  return;
                }
              }
            xhr.send(file);
        });
    }
}