import { EnvContext } from './envProvider';
import { createContext, useContext } from 'react';
import config from '../config';

export const PermissionContext = createContext();

export function PermissionProvider({children, role}) {
	const { env } = useContext(EnvContext);
	const permission = config[env].permissions[role] || null;

	return (
		<PermissionContext.Provider value={{ permission }}>
			{children}
		</PermissionContext.Provider>
	);
}