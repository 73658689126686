import { React, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
    Box,
    Checkbox,
    FormControl,
    FormGroup,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import {
    Download as DownloadIcon,
    Add as AddIcon,
} from '@mui/icons-material';

import { Storage } from 'aws-amplify';

import config from '../config';
import { ApiClient } from '../models/exports'
import { EnvContext, PermissionContext } from '../providers/exports';

import { authManager } from '../models/exports';

const FilterTypes = Object.freeze({
    NONE: Object.freeze({
        name: 'フィルターなし',
        value: 'none',
    }),
    MODEL_NAME: Object.freeze({
        name: 'モデル',
        value: 'model_name',
    }),
    PRODUCT_NAME: Object.freeze({
        name: 'プロダクト',
        value: 'product_name',
    }),
    VERSION_NAME: Object.freeze({
        name: 'バージョンネーム',
        value: 'version_name',
    }),
    TAG: Object.freeze({
        name: 'タグ',
        value: 'tag',
    }),
});

const MatchPaterns = Object.freeze({
    PARTIAL: Object.freeze({
        name: '部分一致',
        value: 0,
    }),
    PERFECT: Object.freeze({
        name: '完全一致',
        value: 1,
    }),
    FORWARD: Object.freeze({
        name: '前方一致',
        value: 2,
    }),
    BACKWARD: Object.freeze({
        name: '後方一致',
        value: 3,
    }),
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export function ListPage() {
    const { env } = useContext(EnvContext);
    const { permission } = useContext(PermissionContext);

    const api = new ApiClient({});

    const [packages, setPackages] = useState([]);
    const [filterOption, setFilterOption] = useState({
        key: FilterTypes.NONE.value,
        value: '',
        matchPatern: MatchPaterns.PARTIAL.value,
        visibleOnly: false,
    });

    useEffect(() => {
        const stored = JSON.parse(localStorage.getItem('filter'));
        if (stored !== null) setFilterOption({...filterOption, ...stored});
        
        getAll();
    }, []);

    useEffect(() => {
        localStorage.setItem('filter', JSON.stringify(filterOption))
    }, [filterOption]);

    const filtering = p => {
        const { key, value, matchPatern, visibleOnly } = filterOption;

        const first = matchPatern == MatchPaterns.PERFECT.value || matchPatern == MatchPaterns.FORWARD.value ? '^' : '';
        const last = matchPatern == MatchPaterns.PERFECT.value || matchPatern == MatchPaterns.BACKWARD.value ? '$' : '';

        const ptn = (() => {
            try {
                return new RegExp(first + filterOption.value.toUpperCase() + last);
            } catch (e) {
                return null;
            }
        })();

        if (ptn === null) return false;

        if (visibleOnly && !p.visibility) return false;


        if (!value || key === 'none') return true;

        if (key === 'tag') {
            for (let tag of p.tags) {
                // if (filterOption.value.test(tag)) return true;
                if (tag.toUpperCase().match(ptn) !== null) return true;
            }
            return false;
        }
        // return filterOption.value.test(p[key]);

        return p[key].toUpperCase().match(ptn) !== null;
    }

    async function getAll() {
        const role = await authManager.getRole();
        if (!role) return;

        const path = `${config[env].tapi.endpoint}/firmwares`;
        // const params = {
        //     'or': `(product_name.eq.IM-A820,model_name.eq.${config[env].apkName})`,
        // }

        const params = {
            'or': '(' + permission.map(v => `${v.type}.eq.${v.value}`).join(',') + ')',
        };

        const [result, err] = await api.get(path, params);
        if (err !== null) return null;

        for (let p of result) {
            p.download_link = await getDownloadLink(p);
        }

        setPackages(result);
        return result;
    }

    async function getDownloadLink({ bin_key }) {
        try {
            if (!bin_key) return;

            const url = await Storage.get(bin_key);
            return url;
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <div>
            <Box m={2} />
            <Grid container>
                <Grid item xs={12} textAlign="right">
                    <Link to="/add" style={{ width: '100%', textAlign: 'center' }}>＋新規登録</Link>
                </Grid>
            </Grid>
            <Box m={2} />

            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Select
                        labelId="key-select-label"
                        id="key-select"
                        value={filterOption.key}
                        fullWidth
                        onChange={e => setFilterOption({ ...filterOption, key: e.target.value })} >
                        {Object.keys(FilterTypes).map((key, i) =>
                            <MenuItem key={i} value={FilterTypes[key].value}>{FilterTypes[key].name}</MenuItem>
                        )}
                    </Select>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        type="text"
                        fullWidth
                        value={filterOption.value}
                        onChange={e => setFilterOption({ ...filterOption, value: e.target.value })}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Select
                        labelId="match-patern-select-label"
                        id="match-patern-select"
                        value={filterOption.matchPatern}
                        fullWidth
                        onChange={e => setFilterOption({ ...filterOption, matchPatern: e.target.value })} >
                        {Object.keys(MatchPaterns).map((key, i) =>
                            <MenuItem key={i} value={MatchPaterns[key].value}>{MatchPaterns[key].name}</MenuItem>
                        )}
                    </Select>
                </Grid>
                <Grid item xs={2}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={filterOption.visibleOnly}
                                    onChange={e => setFilterOption({...filterOption, visibleOnly: e.target.checked})} />
                            }
                            label="公開中のみ" />
                    </FormGroup>
                </Grid>
            </Grid>

            <Box m={2} />

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>id</StyledTableCell>
                            <StyledTableCell align="center">モデル</StyledTableCell>
                            <StyledTableCell align="center">プロダクト</StyledTableCell>
                            <StyledTableCell align="center">バージョンコード</StyledTableCell>
                            <StyledTableCell align="center">バージョンネーム</StyledTableCell>
                            <StyledTableCell align="center">公開状況</StyledTableCell>
                            <StyledTableCell align="center">ファイル</StyledTableCell>
                            <StyledTableCell align="center">タグ</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {packages.filter(p => filtering(p)).map(p => (
                            <StyledTableRow key={p.id}>
                                <StyledTableCell component="th" scope="row"><Link to={`/edit/${p.id}`}>{p.id}</Link></StyledTableCell>
                                <StyledTableCell align="center">{p.model_name}</StyledTableCell>
                                <StyledTableCell align="center">{p.product_name}</StyledTableCell>
                                <StyledTableCell align="center">{p.version_code}</StyledTableCell>
                                <StyledTableCell align="center">{p.version_name}</StyledTableCell>
                                <StyledTableCell align="center">{p.visibility ? '公開' : '非公開'}</StyledTableCell>
                                <StyledTableCell align="center">{p.bin_path ? <a href={p.download_link} download><DownloadIcon color="primary" /></a> : ''} </StyledTableCell>
                                <StyledTableCell align="left">{p.tags.map((tag, i) => (
                                    <div key={i}>
                                        <span>{i + 1}. {tag}</span>
                                        {i < (p.tags.length - 1) ? <br /> : ''}
                                    </div>
                                ))}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}