import { Button, CircularProgress } from '@mui/material';

export function LoadingButton({
	loading = false,
	type = "submit",
	variant = "contained",
	size = "large",
	color = "primary",
	fullWidth = false,
	onClick = f => f,
	children,
}) {
	return (
		<Button
			type={type}
			variant={variant}
			size={size}
			color={color}
			disabled={loading}
			onClick={onClick}
			fullWidth={fullWidth}>
			{ loading ? <CircularProgress color="secondary" size="1.6rem" /> : children }
		</Button>
	);
}