import config from './config';
import { useContext, useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { PermissionProvider } from './providers/exports';

import Amplify from 'aws-amplify';

import {
  ListPage,
  AddPage,
  EditPage,
  SignInPage,
  WithAppBarLayout,
} from './pages/exports';

import { authManager } from './models/exports';
import { EnvContext } from './providers/exports';
import { Env } from './constants/exports';

export default function App() {
  const [signedIn, setSignedIn] = useState(null);
  const [role, setRole] = useState(null);
  const { env, setEnv } = useContext(EnvContext);
  const [theme, setTheme] = useState(createTheme(config[Env.STAGING].theme));

  useEffect(() => {
    if (env === null) {
      const stored = localStorage.getItem('env') !== null ? localStorage.getItem('env') : Env.STAGING;
      setEnv(stored);
      checkUser(stored);
    }
  }, []);

  useEffect(() => {
    if (env !== null) {
      setTheme(createTheme(config[env].theme));
    }
  }, [env]);

  const checkUser = async env => {
    Amplify.configure(config[env].amplify);
    const isLoggedIn = await authManager.isLoggedIn();
    setSignedIn(isLoggedIn);
    if (isLoggedIn) {
      await handleSignIn(env);
    }
  }

  const handleSignIn = async env => {
    const role = await authManager.getRole();
    /*
      タブレットのCognitoユーザーが複数ロール当てられていたため変更
      とりあえずは最初にマッチしたロールを適用する
    */
    // setRole(role);

    // if (Object.keys(config[env].permissions).includes(role)) {
    //   setSignedIn(true);
    // } else {
    //   await authManager.singOut();
    //   setSignedIn(false);
    // }

    if (role != null) {
      const arr = role.replace(/\s+/g, "").split(',');
      let isMatch = false;
      for (const r of arr) {
        if (Object.keys(config[env].permissions).includes(r)) {
          setRole(r);
          setSignedIn(true);
          isMatch = true;
        }
      }
      if (!isMatch) {
        setRole(null);
        setSignedIn(false);
      }
    } else {
      setRole(null);
      setSignedIn(false);
    }
  }

  const handleSignOut = async () => {
    setSignedIn(false);
    setRole(null);
  }

  return (
    <ThemeProvider theme={theme}>
      {signedIn === null || env === null ? <div />
        : signedIn && role != null ? (
          <PermissionProvider role={role}>
            <Router>
              <Switch>
                <WithAppBarLayout handleSignOut={handleSignOut}>
                  <Route exact path='/' component={ListPage} />
                  <Route exact path='/add' component={AddPage} />
                  <Route exact path='/edit/:id' component={EditPage} />
                </WithAppBarLayout>
              </Switch>
            </Router>
          </PermissionProvider>
        ) : (
          <SignInPage handleSignIn={() => handleSignIn(env)} />
        )
      }
    </ThemeProvider>
  );
}
