import config from '../config';
import { useContext, useState, useEffect } from 'react';

import {
    Alert,
    Box,
    Container,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField
} from '@mui/material';

import { LoadingButton } from '../components/exports';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import { EnvContext } from '../providers/exports';

import Amplify from 'aws-amplify';
import { authManager } from '../models/exports';
import { Env } from '../constants/exports';

const formStyle = {
    background: '#FEFEFE',
    boxShadow: '1px 1px 4px 0 rgba(0, 0, 0, 0.15)',
    borderRadius: '4px',
    padding: '20px 20px 60px',
    margin: '100px 10px 0px'
}

function MyAlert({ msg = 'エラーが発生しました', open = false, onClose = f => f }) {
    return (
        <Box sx={{ width: '100%' }}>
            <Collapse in={open}>
                <Alert
                    severity="error"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={onClose}>
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}>
                    {msg}
                </Alert>
            </Collapse>
        </Box>
    );
}


export function SignInPage({ handleSignIn = f => f }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordRequired, setNewPasswordRequired] = useState(false);
    const [loading, setLoading] = useState(false);

    const { env, setEnv } = useContext(EnvContext);

    const [alert, setAlert] = useState({
        open: false,
        msg: '',
    });

    useEffect(() => {
        Amplify.configure(config[env].amplify);
    }, [env]);

    const onChangeEnv = e => {
        const v = e.target.value;
        if (v) {
            setEnv(v)
        }
    };

    const openAlert = msg => {
        setAlert({
            open: true,
            msg: msg,
        });
    }

    const closeAlert = () => {
        setAlert({
            open: false,
            msg: '',
        });
    }

    const signIn = async e => {
        e.preventDefault();

        setLoading(true);

        await (async () => {
            const [user, err] = await authManager.signIn(username, password);

            if (err) {
                console.log(err);
                if (err.code === 'NotAuthorizedException') {
                    openAlert('ユーザー名かパスワードが違います')
                } else {
                    openAlert('エラーが発生しました')
                }

                return;
            }

            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                setNewPasswordRequired(true);
            } else {
                localStorage.setItem('env', env);
                handleSignIn();
            }
        })();

        setLoading(false);
    };

    const completeNewPassword = async e => {
        e.preventDefault();

        if (newPassword.length < 6) {
            openAlert('パスワードは6文字以上入力してくだい')
        }

        setLoading(true);

        await (async () => {
            const [, err] = await authManager.completeNewPassword(newPassword);

            if (err) {
                openAlert('エラーが発生しました')
                return;
            }

            localStorage.setItem('env', env);
            handleSignIn();
        })();

        setLoading(false);
    };

    return (
        <div>
            <MyAlert msg={alert.msg} open={alert.open} onClose={closeAlert} />
            <Container maxWidth="sm">
                {!newPasswordRequired ? (
                    <form onSubmit={signIn} style={formStyle}>
                        <Grid container>
                            <Grid item xs={8}>
                                <RadioGroup row
                                    aria-label="gender"
                                    name="env"
                                    value={env}
                                    onClick={onChangeEnv}>

                                    <FormControlLabel
                                        value={Env.DEVELOP}
                                        control={<Radio />}
                                        label="開発" />

                                    <FormControlLabel
                                        value={Env.STAGING}
                                        control={<Radio />}
                                        label="検証" />

                                    <FormControlLabel
                                        value={Env.PRODUCTION}
                                        control={<Radio />}
                                        label="本番" />
                                </RadioGroup>
                            </Grid>

                        </Grid>
                        <TextField
                            type="text"
                            label="ユーザー名"
                            value={username}
                            placeholder="ユーザー名を入力してください"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={e => setUsername(e.target.value)}
                            fullWidth={true}
                            variant="outlined"
                            required={true}
                            size="small"
                            margin="normal" />

                        <Box m={1} />

                        <TextField
                            type="password"
                            value={password}
                            label="パスワード"
                            placeholder="パスワードを入力してください"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={e => setPassword(e.target.value)}
                            fullWidth={true}
                            variant="outlined"
                            required={true}
                            size="small"
                            margin="normal" />

                        <Box m={4} />

                        <LoadingButton
                            loading={loading}
                            fullWidth>
                            ログイン
                        </LoadingButton>
                    </form>

                ) : (
                    <form onSubmit={completeNewPassword} style={formStyle}>
                        <h3>パスワードを変更してください</h3>
                        <h5>※初回ログインのみ表示されます</h5>

                        <TextField
                            type="password"
                            value={newPassword}
                            label="新しいパスワード"
                            placeholder="新しいパスワードを入力してください"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={e => setNewPassword(e.target.value)}
                            fullWidth={true}
                            variant="outlined"
                            required={true}
                            size="small"
                            margin="normal" />

                        <Box m={4} />

                        <LoadingButton
                            loading={loading}
                            fullWidth>
                            変更
                        </LoadingButton>
                    </form>
                )}
            </Container>
        </div>
    );
}
