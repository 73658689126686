export const ProductNames = Object.freeze({
    Android: Object.freeze({
        name: 'Androidアプリ',
        value: 'android',
    }),
    IM_A820: Object.freeze({
        name: 'IM-A820',
        value: 'IM-A820',
    }),
    ABT: Object.freeze({
        name: 'ABT',
        value: 'ABT',
    }),
    PT_830: Object.freeze({
        name: 'PT-830',
        value: 'PT-830',
    }),
});